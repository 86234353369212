import templateService from '../../services/templateService';
import messageService from '../../services/messageService';
import TemplateInputWithPreview from '@/components/sendTemplateMessagePopup/templateInputWithPreview/templateInputWithPreview';
import ChatSettingsService from '../../services/chatSettingsService';
// import * as _ from 'lodash';

export default {
  name: 'SendTemplateMessagePopup',
  components: {
    TemplateInputWithPreview
  },
  props: {
    isSendingFromChatBox: {
      type: Boolean,
      default: false
    },
    channelId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      sendingLoader: false,
      visible: false,

      allTemplates: null,
      selectedTemplateIndex: null,

      whatsAppChannel: null,
      configuredWhatsAppChannels: [],

      template: null,
      customer: null
    };
  },

  methods: {
    async fetchConfiguredWhatsAppChannels() {
      try {
        let response = await ChatSettingsService.getChannelListForDropdown({
          channelType: 'whatsApp'
        });
        this.configuredWhatsAppChannels = response.data;

        // Set Default Selected Channel.
        if (this.configuredWhatsAppChannels.length > 0 && !this.whatsAppChannel) {
          this.whatsAppChannel = this.configuredWhatsAppChannels[0].id;
        }

        this.fetchAllTemplates();
      } catch (err) {
        console.error('Failed to fetch configured whatsapp channels.', err);
      }
    },

    async onWhatsAppChannelChange() {
      this.fetchAllTemplates();
      this.selectedTemplateIndex = null;
    },

    async fetchAllTemplates() {
      try {
        this.loading = true;
        let response = await templateService.getTemplateListForSendingMessage({
          channelId: this.whatsAppChannel
        });
        this.allTemplates = response.data;
        this.loading = false;
      } catch (e) {
        console.error('Failed to fetch templates', e);
        this.errorToast('Failed to fetch templates. Please contact support.');
      }
    },

    onSelectedTemplateChange() {
      let templateInfo = this.allTemplates[this.selectedTemplateIndex];

      // let selectedTemplateId = this.selectedTemplateId;
      // let templateInfo = _.find(this.allTemplates, (tmpl) => {
      //   return tmpl.id == selectedTemplateId;
      // });

      this.applyTemplate(templateInfo);
    },

    async showPopup(customer, conversationId) {
      this.customer = customer;
      this.conversationId = conversationId;

      this.whatsAppChannel = this.channelId;
      if (this.whatsAppChannel == null) {
        requestAnimationFrame(this.fetchConfiguredWhatsAppChannels);
      }

      // Clear selected template.
      this.selectedTemplateIndex = null;
      this.template = null;

      this.visible = true;
    },

    applyTemplate(template) {
      this.template = template;
      this.$refs.TemplateInputWithPreview.applyTemplate(template, template.variables, template);
    },

    async sendTemplateMessage() {
      try {
        // Check if template is selected.
        this.sendingLoader = true;

        // Check if template selected.
        if (!this.template) {
          this.warningToast('Please select template to send.');
          return;
        }

        // Check if variables are valid.
        let isInputValid = this.$refs.TemplateInputWithPreview.isValidInput();
        if (!isInputValid) {
          this.warningToast('Please provide valid template input.');
          return;
        }

        // Prepare Input.
        let templateInput = this.$refs.TemplateInputWithPreview.getProcessedInputForSend();
        let input = JSON.parse(JSON.stringify(templateInput));
        input['template'] = this.template;

        let response = await messageService.sendTemplateMessage({
          conversationId: this.conversationId,
          input: input,
          customerId: this.customer.id,
          senderType: 'agent',
          agentId: this.$store.state.token.userId
        });

        if (response.data.success) {
          this.successToast('Message Sent.');
          if (this.isSendingFromChatBox) {
            this.$emit('onNewMessageSent', null);
          }

          this.visible = false;
        } else {
          // Show error details in popup.
          let sendError = response.data;
          let html = `
            <div class="w-full font-bold text-red-400">${sendError.message}</div>
            <div class="py-1 font-gray-600">${sendError.description}</div>
          `;
          this.$alert(html, 'Failed', {
            confirmButtonText: 'Okay',
            customClass: 'errorPopup',
            dangerouslyUseHTMLString: true
          });
        }
      } catch (err) {
        this.errorToast('Failed to send template message, please contact support.');
        console.error('Failed to send template message.', err);
      } finally {
        this.sendingLoader = false;
      }
    }
  },
  mounted() {}
};
