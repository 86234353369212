<template>
  <el-dialog append-to-body custom-class="sendTemplateMessageDialog" :visible.sync="visible" width="900px" center title="Send Template Message" :destroy-on-close="true">
    <div v-if="loading" v-loading="true" class="w-full h-80 mt-6"></div>

    <!-- Form -->
    <div v-else>
      <template>
        <!-- Customer Info -->
        <div class="border-b bg-gray-50 px-3 py-3" v-if="customer">
          <div class="text-gray-700 font-semibold">Send To</div>
          <el-row class="text-gray-700">
            <el-col :span="8">{{ customer.name }}</el-col>
            <el-col :span="8">{{ customer.mobile }}</el-col>
            <el-col :span="8">{{ customer.email ? customer.email : 'Not Available' }}</el-col>
          </el-row>
        </div>

        <!-- Select Template -->
        <div class="border-b bg-gray-50 flex">
          <div class="p-3">
            <div class="text-gray-700 font-semibold">WhatsApp Account</div>
            <el-select class="w-full" size="medium" placeholder="Select WhatsApp Account" v-model="whatsAppChannel" filterable @change="onWhatsAppChannelChange">
              <el-option v-for="(item, index) in configuredWhatsAppChannels" :key="index" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
          <div class="p-3 flex-1">
            <div class="text-gray-700 font-semibold">Select Template</div>
            <el-select class="w-full" size="medium" placeholder="Select Template" v-model="selectedTemplateIndex" filterable @change="onSelectedTemplateChange">
              <el-option v-for="(item, index) in allTemplates" :key="index" :label="item.name" :value="index">
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type }}</span>
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- <template details -->
        <TemplateInputWithPreview ref="TemplateInputWithPreview"></TemplateInputWithPreview>
      </template>
    </div>

    <!-- Footer -->
    <template v-if="!loading" #footer>
      <div class="text-right w-full py-0">
        <template>
          <el-button :loading="sendingLoader" size="medium" @click="sendTemplateMessage()" type="primary">Send Template Message &nbsp; <i class="fa-solid fa-paper-plane"></i></el-button>
          <el-button size="medium" @click="visible = false" type="danger">Cancel</el-button>
        </template>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" src="./sendTemplateMessagePopup.scss"></style>

<script>
import sendTemplateMessagePopupComponent from './sendTemplateMessagePopupComponent';
export default sendTemplateMessagePopupComponent;
</script>
