const axios = require('axios');
const { messageServiceUrl, buildAuthHeaders } = require('./settings.js');

exports.sendTemplateMessage = function (params) {
  return axios.post(messageServiceUrl + '/message/template', params, {
    headers: buildAuthHeaders()
  });
};

exports.sendSessionMessage = function (params) {
  return axios.post(messageServiceUrl + '/message/session', params, {
    headers: buildAuthHeaders()
  });
};
